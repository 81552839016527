export default class AppConfig {

    static get GCP_PROJECT_ID() {
        return "kujakuja-crc-live";
    }

    static get API_URL() {
        return "https://api.crc.kujakuja.com/api/v3/";
    }

    static get LOGIN_REQUIRED() {
        return true;
    }

    static get DEFAULT_LOGIN_EMAIL() {
        return "website@kujakuja.com";
    }

    static get DEFAULT_LOGIN_PASSWORD() {
        return "website_pass";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get GOOGLE_TRANSLATE_API_KEY() {
        return "AIzaSyBaC7aIcpV9yUjOjqhLWX0s9U1-w1VD3NI";
    }

    static get TOP_KEYWORDS_ENABLED() {
        return false;
    }

    static get TOP_KEYWORDS_LOGIN_URL() {
        return "https://kujakuja.co/login";
    }

    static get TOP_KEYWORDS_LOGIN_USERNAME() {
        return "";
    }

    static get TOP_KEYWORDS_LOGIN_PASSWORD() {
        return "";
    }

    static get TOP_KEYWORDS_API_URL() {
        return "";
    }

    static get DATA_STUDIO_GRAPH_URL() {
        return "https://datastudio.google.com/embed/reporting/170fd186-53f2-4634-ad1b-198b2508a1ee/page/yipdB";
    }
}
